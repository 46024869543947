* {
  box-sizing: border-box;
}

:root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --font-family: "UrbanistLight";

  color-scheme: light dark;
  color: #444;
  background: #fafafa;
}
@font-face {
  font-family: "UrbanistLight";
  src: url("../fonts/Urbanist-Light.ttf") format("woff"),
    url("../fonts/Urbanist-Light.ttf") format("woff2"),
    url("../fonts/Urbanist-Light.ttf") format("truetype"),
    url("../fonts/Urbanist-Light.ttf") format("svg");
  font-style: normal;
  font-weight: normal;
}

@media (prefers-color-scheme: dark) {
  :root {
    color: #000000;
    background: #f1f1f1;
  }
}

body {
  font-family: "UrbanistLight", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-smooth: always;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}
/*

#app > main {
	min-height: calc(100vh - 3.5rem);
	max-width: 1280px;
	
}

@media (max-width: 639px) {
	#app > main {
		margin: 0 2rem;
	}
}
*/
